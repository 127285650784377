import { useState, useEffect, useContext, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { ArrowBackOutlined } from "@mui/icons-material";
import { format } from "date-fns";
import { UserContext } from "../context/UserContext";
import { useSnackbar } from "react-simple-snackbar";
import Section from "../components/Section";
import Container from "../components/Container";
import Row from "../components/Row";
import Column from "../components/Column";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/esm/Button";
import Table from "react-bootstrap/Table";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import BookingImg from "../components/BookingImg";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const Booking = ({ access }) => {
	let { id } = useParams();
	const navigate = useNavigate();
	const selfCheckinRef = useRef(null);
	const selfCheckoutRef = useRef(null);
	const [user] = useContext(UserContext);
	const [loading, setLoading] = useState(true);
	const [code, setCode] = useState("");
	const [confirmLoading, setConfirmLoading] = useState(false);
	const [actionLoading, setActionLoading] = useState(false);
	const [rejectLoading, setRejectLoading] = useState(false);
	const [cancelLoading, setCancelLoading] = useState(false);
	const [revertLoading, setRevertLoading] = useState(false);
	const [checkoutLoading, setCheckoutLoading] = useState(false);
	const [showCodeModal, setShowCodeModal] = useState(false);
	const [showImage, setShowImage] = useState(null);
	const [guestToEdit, setGuestToEdit] = useState(null);
	const [showGuestCheckoutModal, setShowGuestCheckoutModal] = useState(false);
	const [showRejectModal, setShowRejectModal] = useState(false);
	const [showCancelModal, setShowCancelModal] = useState(false);
	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [showRevertModal, setShowRevertModal] = useState(false);
	const [showCheckoutModal, setShowCheckoutModal] = useState(false);
	const [booking, fetchBooking] = useState([]);
	const [primaryGuest, fetchPrimaryGuest] = useState([]);
	const [passes, setPasses] = useState([]);
	const [remarks, setRemarks] = useState([]);
	const [selfEdit, setSelfEdit] = useState(null);
	const [bookingRemarks, setBookingRemarks] = useState("");
	const [selfRemarks, setSelfRemarks] = useState("");
	const [zoomLevel, setZoomLevel] = useState(100);

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const getData = () => {
		fetch(`${process.env.REACT_APP_SERVER_URI}/bookings/b/${id}`)
			.then((res) => res.json())
			.then((res) => {
				fetchBooking(res.data);
				fetchPrimaryGuest(res.primary_guest);
				setPasses(res.data.guests);
				setLoading(false);
			})
			.catch((error) => {
				navigate("/pmo/bookings");
			});
	};

	const saveCode = async (e) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				booking_id: booking.id,
				guest_id: guestToEdit.id,
				code: code,
				remarks: remarks,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/codes/save`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			setShowCodeModal(false);
			fetchBooking(data.booking);
			setPasses(data.booking.guests);
			successSnackbar(data.msg);
		}
		setActionLoading(false);
	};

	const checkoutGuest = async () => {
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				guest_id: guestToEdit.id,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/guests/checkout`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			setShowGuestCheckoutModal(false);
			fetchBooking(data.booking);
			// fetchPrimaryGuest(data.primary_guest);
			setPasses(data.booking.guests);

			successSnackbar(data.msg);
		}
		setActionLoading(false);
	};

	const revertBooking = async () => {
		setActionLoading(true);
		setRevertLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				status: "Confirmed",
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/reset/${id}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			setShowRevertModal(false);
			fetchBooking(data.booking);
			setPasses(data.booking.guests);
			successSnackbar(data.msg);
		}
		setActionLoading(false);
		setRevertLoading(false);
	};

	const changeStatus = async (e, action) => {
		e.preventDefault();
		if (action == "confirm") {
			var loadBtn = setConfirmLoading;
		} else if (action == "reject") {
			var loadBtn = setRejectLoading;
		} else if (action == "cancel") {
			var loadBtn = setCancelLoading;
		} else {
			var loadBtn = setCheckoutLoading;
		}

		setActionLoading(true);
		loadBtn(true);

		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				booking_id: booking.id,
				remarks: bookingRemarks,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/${action}`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg);
		} else {
			fetchBooking(data.booking);
			successSnackbar(data.msg);
			setPasses(data.booking.guests);
		}
		setActionLoading(false);
		setShowRejectModal(false);
		setShowCancelModal(false);
		setShowConfirmModal(false);
		setShowCheckoutModal(false);
		loadBtn(false);
	};

	const editSelf = async (e, key, value) => {
		e.preventDefault();
		setActionLoading(true);
		const requestOptions = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": user ? user.auth_token : "",
			},
			body: JSON.stringify({
				booking_id: booking.id,
				key: key,
				value: value,
				remarks: selfRemarks,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/self/edit`,
			requestOptions
		);
		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar("Something went wrong.");
		} else {
			successSnackbar(data.msg);
			setSelfEdit(null);
			setSelfRemarks("");
		}
		setActionLoading(false);
	};

	const handlePassChange = (guestId, guestPass) => {
		var guestPasses = [...passes];
		if (guestPasses.filter((value) => value.id == guestId).length != 0) {
			guestPasses.filter((value) => value.id == guestId)[0].visitor_pass =
				guestPass;
		} else {
			guestPasses.push({ id: guestId, visitor_pass: guestPass });
		}
		setPasses(guestPasses);
	};

	const handleGuestToEdit = (guest, showModal) => {
		setGuestToEdit(guest);
		setCode(guest.visitor_pass || "");
		showModal(true);
	};

	const handleSelf = (key, value) => {
		setSelfEdit({
			key: key,
			value: value,
		});
	};

	const handleSelfClose = (key) => {
		setSelfEdit(null);
		setSelfRemarks("");

		if (key === "self_checkin") {
			selfCheckinRef.current.checked = !selfCheckinRef.current.checked;
		} else {
			selfCheckoutRef.current.checked = !selfCheckoutRef.current.checked;
		}
	};

	const handleZoomIn = () => {
		if (zoomLevel < 200) {
			setZoomLevel(zoomLevel + 10);
		}
	};

	const handleZoomOut = () => {
		if (zoomLevel > 10) {
			setZoomLevel(zoomLevel - 10);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<>
			<div className="page-header flex-row-left">
				<Link
					to={`/pmo/bookings${window.location.search}`}
					className="flex-row-left"
				>
					<ArrowBackOutlined
						className="back-icon blue-text me-2"
						sx={{ fontSize: 28 }}
					/>
					{loading ? (
						""
					) : (
						<span className="back-label default-text">
							{booking.ref_no} | {booking.primary_guest_name}
						</span>
					)}
				</Link>
			</div>
			<Section className="content">
				{loading ? (
					<div className="loading flex-column">Loading</div>
				) : (
					<div className="info-container white-bg p-5">
						<Tabs defaultActiveKey="info" className="mb-3">
							<Tab eventKey="info" title="Details">
								<Container>
									<Row className="mt-5 mb-3">
										<Column className="p-0">
											<span className="guests-header">Booking Details</span>
										</Column>
									</Row>
									<Row>
										<Column className="info-col">
											<label className="form-label">Reference Number</label>
											<span className="info-text">{booking.ref_no}</span>
										</Column>
										<Column className="info-col">
											<label className="form-label">Date Requested</label>
											<span className="info-text">
												{format(
													new Date(booking.created_at),
													"MMM dd, yyyy (hh:mm a)"
												)}
											</span>
										</Column>
										<Column className="info-col">
											<label className="form-label">Status</label>
											<span className="info-text">
												{booking.display_status}
											</span>
										</Column>
									</Row>
									<Row>
										<Column className="info-col">
											<label className="form-label">Arrival</label>
											<span className="info-text">
												{format(
													new Date(booking.arrival),
													"MMM dd, yyyy (hh:mm a)"
												)}
											</span>
										</Column>
										<Column className="info-col">
											<label className="form-label">Departure</label>
											<span className="info-text">
												{format(
													new Date(booking.departure),
													"MMM dd, yyyy (hh:mm a)"
												)}
											</span>
										</Column>
									</Row>
									<Row>
										<Column className="info-col">
											<label className="form-label">Adults</label>
											<span className="info-text">{booking.adults}</span>
										</Column>
										<Column className="info-col">
											<label className="form-label">Children</label>
											<span className="info-text">{booking.children}</span>
										</Column>
										<Column className="info-col">
											<label className="form-label">Self Check In</label>
											<span className="info-text">
												{/* {booking.self_checkin ? "Yes" : "No"} */}
												<input
													ref={selfCheckinRef}
													type="checkbox"
													className="cbx hidden"
													defaultChecked={booking.self_checkin}
													id="selfCheckin"
													onChange={() =>
														handleSelf("self_checkin", !booking.self_checkin)
													}
												/>
												<label htmlFor="selfCheckin" className="lbl"></label>
											</span>
										</Column>
										<Column className="info-col">
											<label className="form-label">Self Check Out</label>
											<span className="info-text">
												{/* {booking.self_checkout ? "Yes" : "No"} */}
												<input
													ref={selfCheckoutRef}
													type="checkbox"
													className="cbx hidden"
													defaultChecked={booking.self_checkout}
													id="selfCheckout"
													onChange={() =>
														handleSelf("self_checkout", !booking.self_checkout)
													}
												/>
												<label htmlFor="selfCheckout" className="lbl"></label>
											</span>
										</Column>
									</Row>
									<Row className="mt-5 mb-3">
										<Column className="p-0">
											<span className="guests-header">
												Unit Owner/SPA Details
											</span>
										</Column>
									</Row>
									<Row>
										<Column className="info-col">
											<label className="form-label">Owner/SPA Name</label>
											<span className="info-text">
												{booking.spa ? booking.spa.name : booking.tenant.name}
											</span>
										</Column>
										<Column className="info-col">
											<label className="form-label">Tower & Unit</label>
											<span className="info-text">
												{booking.unit.tower} {booking.unit.name}
											</span>
										</Column>
										<Column className="info-col">
											<label className="form-label">Unit Type</label>
											<span className="info-text">{booking.unit.type}</span>
										</Column>
									</Row>
									<Row>
										<Column className="info-col">
											<label className="form-label">
												Onsite Contact Person
											</label>
											<span className="info-text">
												{booking.contact_person
													? booking.contact_person
													: "N/A"}
											</span>
										</Column>
										<Column className="info-col">
											<label className="form-label">Contact Number</label>
											<span className="info-text">
												{booking.contact_person_msisdn
													? booking.contact_person_msisdn
													: "N/A"}
											</span>
										</Column>
									</Row>
									<Row className="mt-5 mb-3">
										<Column className="p-0">
											<span className="guests-header">Primary Guest</span>
										</Column>
									</Row>
									<div className="guest-row mb-5">
										<Row className="header-row">
											<Column>
												<span className="white-text">
													{booking.primary_guest_name}
												</span>
											</Column>
										</Row>
										<Row>
											<Column className="info-col">
												<label className="form-label">Age</label>
												<span className="info-text">{primaryGuest.age}</span>
											</Column>
											<Column className="info-col">
												<label className="form-label">Nationality</label>
												<span className="info-text">
													{primaryGuest.nationality}
												</span>
											</Column>
											<Column className="info-col">
												<label className="form-label">Status</label>
												<span className="info-text">{primaryGuest.status}</span>
											</Column>
										</Row>
										<Row>
											<Column className="info-col">
												<label className="form-label">Address</label>
												<span className="info-text">
													{primaryGuest.address}
												</span>
											</Column>
										</Row>
										<Row>
											<Column className="info-col">
												<label className="form-label">Email</label>
												<span className="info-text">{primaryGuest.email}</span>
											</Column>
											<Column className="info-col">
												<label className="form-label">Contact Number</label>
												<span className="info-text">{primaryGuest.msisdn}</span>
											</Column>
										</Row>
										<Row>
											<Column className="info-col">
												<label className="form-label">Vehicle</label>
												<span className="info-text">
													{primaryGuest.vehicle.length !== 0
														? `${primaryGuest.vehicle[0].model} (${primaryGuest.vehicle[0].type}) - ${primaryGuest.vehicle[0].plate_no}`
														: "N/A"}
												</span>
											</Column>
											<Column className="info-col">
												<label className="form-label">Parking</label>
												<span className="info-text">
													{primaryGuest.vehicle.length !== 0 &&
													primaryGuest.vehicle[0].with_parking
														? `${primaryGuest.vehicle[0].parking_tower} - ${primaryGuest.vehicle[0].parking_level}, ${primaryGuest.vehicle[0].parking_slot}`
														: "N/A"}
												</span>
											</Column>
										</Row>
										<Row>
											<Column className="info-col">
												<label className="form-label">Valid ID</label>
												{primaryGuest.id_img ? (
													<BookingImg
														className="booking-img"
														src={primaryGuest.id_img}
														setShowImage={setShowImage}
													/>
												) : (
													"N/A"
												)}
											</Column>
											<Column className="info-col">
												<label className="form-label">Vax Cert.</label>
												{primaryGuest.vax_img ? (
													<BookingImg
														className="booking-img"
														src={primaryGuest.vax_img}
														setShowImage={setShowImage}
													/>
												) : (
													"N/A"
												)}
											</Column>
										</Row>
									</div>

									{booking.guests.filter((guest) => !guest.is_primary).length >
										0 && (
										<Row className="mt-5 mb-3">
											<Column className="p-0">
												<span className="guests-header">Additional Guests</span>
											</Column>
										</Row>
									)}
									{booking.guests
										.filter((guest) => !guest.is_primary)
										.map((guest) => (
											<div key={guest.id} className="guest-row mb-5">
												<Row className="header-row">
													<Column>
														<span className="white-text">{guest.name}</span>
													</Column>
												</Row>

												<Row>
													<Column className="info-col">
														<label className="form-label">Age</label>
														<span className="info-text">{guest.age}</span>
													</Column>
													<Column className="info-col">
														<label className="form-label">Below 3 Feet</label>
														<span className="info-text">
															{guest.below_min_height ? "Yes" : "No"}
														</span>
													</Column>
													<Column className="info-col">
														<label className="form-label">Status</label>
														<span className="info-text">{guest.status}</span>
													</Column>
												</Row>
												<Row>
													<Column className="info-col">
														<label className="form-label">Vehicle</label>
														<span className="info-text">
															{guest.vehicle.length !== 0
																? `${guest.vehicle[0].model} (${guest.vehicle[0].type}) - ${guest.vehicle[0].plate_no}`
																: "N/A"}
														</span>
													</Column>
													<Column className="info-col">
														<label className="form-label">Parking</label>
														<span className="info-text">
															{guest.vehicle.length !== 0 &&
															guest.vehicle[0].with_parking
																? `${guest.vehicle[0].parking_tower} - ${guest.vehicle[0].parking_level}, ${guest.vehicle[0].parking_slot}`
																: "N/A"}
														</span>
													</Column>
												</Row>
												<Row>
													<Column className="info-col">
														<label className="form-label">Valid ID</label>
														{guest.id_img ? (
															<BookingImg
																className="booking-img"
																src={guest.id_img}
																setShowImage={setShowImage}
															/>
														) : (
															"N/A"
														)}
													</Column>
													<Column className="info-col">
														<label className="form-label">Vax Cert.</label>
														{guest.vax_img ? (
															<BookingImg
																className="booking-img"
																src={guest.vax_img}
																setShowImage={setShowImage}
															/>
														) : (
															"N/A"
														)}
													</Column>
												</Row>
											</div>
										))}
									<Row className="mt-5 mb-3">
										<Column className="p-0">
											<span className="guests-header">Swimbands</span>
										</Column>
									</Row>
									<Row>
										<Column className="info-col">
											<label className="form-label">Total Swim Bands</label>
											<span className="info-text">
												{booking.total_swim_bands}
											</span>
										</Column>
										<Column className="info-col">
											<label className="form-label">Total Amount</label>
											<span className="info-text">
												PHP {booking.total_amount}
											</span>
										</Column>
										<Column className="info-col">
											<label className="form-label">Mode of Payment</label>
											<span className="info-text">
												{booking.total_amount === 0
													? "N/A"
													: booking.payment_mode}
											</span>
										</Column>
									</Row>
									<Row className="mt-5 mb-3">
										<Column className="p-0">
											<span className="guests-header">Remarks</span>
										</Column>
									</Row>
									<Row>
										<Column className="info-col">
											<p className="info-text">{booking.remarks || "N/A"}</p>
										</Column>
									</Row>
									<Row>
										<Column className="action-col flex-row-right mt-5">
											{booking.status !== "Confirmed" &&
												booking.status !== "Pending" &&
												access.bookings_edit && (
													<Button
														type="button"
														variant="outline-secondary"
														className="booking-action-btn ms-3"
														disabled={actionLoading}
														onClick={() => setShowRevertModal(true)}
													>
														Revert
													</Button>
												)}
											{booking.status === "Pending" &&
											booking.display_status !== "For Revision" &&
											access.bookings_edit ? (
												<>
													<Button
														type="button"
														variant="outline-danger"
														className="booking-action-btn"
														onClick={() => setShowRejectModal(true)}
														disabled={actionLoading}
													>
														Reject
													</Button>
													<Button
														type="button"
														variant="primary"
														className="booking-action-btn ms-3"
														disabled={actionLoading}
														onClick={() => setShowConfirmModal(true)}
													>
														Confirm
													</Button>
												</>
											) : booking.status == "Confirmed" &&
											  access.bookings_edit ? (
												<Button
													type="button"
													variant="outline-danger"
													className="booking-action-btn"
													onClick={() => setShowCancelModal(true)}
													disabled={actionLoading}
												>
													Cancel
												</Button>
											) : booking.status == "Checked In" &&
											  access.bookings_checkin ? (
												<>
													{access.bookings_edit && (
														<Button
															type="button"
															variant="outline-danger"
															className="booking-action-btn"
															onClick={() => setShowCancelModal(true)}
															disabled={actionLoading}
														>
															Cancel
														</Button>
													)}
													<Button
														type="button"
														variant="outline-warning"
														className="booking-action-btn"
														disabled={actionLoading}
														onClick={() => setShowCheckoutModal(true)}
													>
														Check Out
													</Button>
												</>
											) : (
												""
											)}
										</Column>
									</Row>
								</Container>
							</Tab>
							{access.bookings_checkin && (
								<Tab eventKey="pass" title="Passes">
									<Container>
										{booking.guests
											.filter((guest) => guest.is_primary === true)
											.map((guest) => (
												<div key={guest.id}>
													<Row className="header-row mt-5">
														<Column>
															<span className="white-text">
																{guest.name} (Primary)
															</span>
														</Column>
													</Row>
													<Row className="info-col flex-row-left">
														<Column>
															<input
																type="text"
																className="form-control"
																disabled
																value={
																	passes.filter((value) => value.id == guest.id)
																		.length != 0
																		? passes.filter(
																				(value) => value.id == guest.id
																		  )[0].visitor_pass
																		: ""
																}
															/>
														</Column>
														<Column className="flex-row-right">
															{guest.verified && (
																<span className="verified-lbl">Verified</span>
															)}
															{guest.status !== "Checked In" ? (
																<Button
																	type="button"
																	variant="primary"
																	className="booking-action-btn ms-3 mb-0 text-sm"
																	disabled={
																		booking.status !== "Confirmed" &&
																		booking.status !== "Checked In"
																	}
																	onClick={() =>
																		handleGuestToEdit(guest, setShowCodeModal)
																	}
																>
																	{/* {guest.status} */}
																	Check In
																</Button>
															) : (
																<Button
																	type="button"
																	variant="primary"
																	className="booking-action-btn ms-3 mb-0 text-sm"
																	disabled={booking.status !== "Checked In"}
																	onClick={() =>
																		handleGuestToEdit(guest, setShowCodeModal)
																	}
																>
																	Edit
																</Button>
															)}
															<Button
																type="button"
																variant="primary"
																className="booking-action-btn ms-3 mb-0 text-sm"
																disabled={guest.status !== "Checked In"}
																onClick={() =>
																	handleGuestToEdit(
																		guest,
																		setShowGuestCheckoutModal
																	)
																}
															>
																Check Out{" "}
																{guest.status === "Pending Checkout" &&
																	"(Pending)"}
															</Button>
														</Column>
													</Row>
												</div>
											))}
										{booking.guests
											.filter((guest) => guest.is_primary === false)
											.sort((a, b) => a.name.localeCompare(b.name))
											.map((guest) => (
												<div key={guest.id}>
													<Row className="header-row mt-5">
														<Column>
															<span className="white-text">{guest.name}</span>
														</Column>
													</Row>
													<Row className="info-col flex-row-left">
														<Column>
															<input
																type="text"
																className="form-control"
																disabled
																value={
																	passes.filter((value) => value.id == guest.id)
																		.length != 0
																		? passes.filter(
																				(value) => value.id == guest.id
																		  )[0].visitor_pass
																		: ""
																}
															/>
														</Column>
														<Column className="flex-row-right">
															{guest.verified && (
																<span className="verified-lbl">Verified</span>
															)}
															{guest.status !== "Checked In" ? (
																<Button
																	type="button"
																	variant="primary"
																	className="booking-action-btn ms-3 mb-0 text-sm"
																	disabled={
																		booking.status !== "Confirmed" &&
																		booking.status !== "Checked In"
																	}
																	onClick={() =>
																		handleGuestToEdit(guest, setShowCodeModal)
																	}
																>
																	Check In
																</Button>
															) : (
																<Button
																	type="button"
																	variant="primary"
																	className="booking-action-btn ms-3 mb-0 text-sm"
																	disabled={booking.status !== "Checked In"}
																	onClick={() =>
																		handleGuestToEdit(guest, setShowCodeModal)
																	}
																>
																	Edit
																</Button>
															)}
															<Button
																type="button"
																variant="primary"
																className="booking-action-btn ms-3 mb-0 text-sm"
																disabled={guest.status !== "Checked In"}
																onClick={() =>
																	handleGuestToEdit(
																		guest,
																		setShowGuestCheckoutModal
																	)
																}
															>
																Check Out{" "}
																{guest.status === "Pending Checkout" &&
																	"(Pending)"}
															</Button>
														</Column>
													</Row>
												</div>
											))}
										{/* <Row>
										<Column className="action-col mt-5">
											<Button
												type="button"
												variant="primary"
												className="booking-action-btn ms-3 pull-right"
												disabled={saveLoading}
												onClick={saveCodes}
											>
												{saveLoading ? "Saving.." : "Save"}
											</Button>
										</Column>
									</Row> */}
									</Container>
								</Tab>
							)}
							<Tab eventKey="parking" title="Pay Parking">
								<Container>
									<table className="content-table white-bg full">
										<thead className="blue-text">
											<tr>
												<td>Plate No.</td>
												<td>Parking Slot</td>
												<td>Status</td>
											</tr>
										</thead>
										<tbody>
											{booking.parking_transactions?.map((transaction) => (
												<tr>
													<td>{transaction.vehicle_plate_no}</td>
													<td>
														{transaction.parking_tower}
														{transaction.parking_level &&
															` - ${transaction.parking_level}`}
														{" - "}
														{transaction.parking_slot}
													</td>
													<td>{transaction.status}</td>
												</tr>
											))}
										</tbody>
									</table>
								</Container>
							</Tab>
							<Tab eventKey="activity" title="Activity">
								<Table className="content-table white-bg">
									<thead className="blue-text">
										<tr>
											<th>Date & Time</th>
											<th>Description</th>
											<th>Staff/User</th>
										</tr>
									</thead>
									<tbody>
										{booking.logs.length != 0
											? booking.logs
													.sort((a, b) =>
														b.created_at.localeCompare(a.created_at)
													)
													.map((log) => (
														<tr key={log.id}>
															<td>
																{format(
																	new Date(log.created_at),
																	"MMM dd, yyyy - hh:mm a"
																)}
															</td>
															<td>{log.content}</td>
															<td>
																{log.user
																	? log.user.name
																	: log.tenant
																	? `${log.tenant.name} (Unit Owner)`
																	: `${log.spa.name} (SPA)`}
															</td>
														</tr>
													))
											: ""}
									</tbody>
								</Table>
							</Tab>
						</Tabs>
					</div>
				)}
			</Section>
			<Modal show={showCodeModal} onHide={() => setShowCodeModal(false)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Visitor's Pass
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={saveCode}>
					<Modal.Body className="pt-4 pb-4">
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>Visitor's Pass No.</Form.Label>
							<Form.Control
								required
								type="text"
								value={code}
								onChange={(e) => setCode(e.target.value)}
								autoFocus
							/>
						</Form.Group>
						{guestToEdit &&
							guestToEdit.visitor_pass &&
							guestToEdit.status === "Checked In" && (
								<Form.Group
									className="mb-3"
									controlId="exampleForm.ControlInput1"
								>
									<Form.Label>Remarks</Form.Label>
									<Form.Control
										required
										as="textarea"
										rows={5}
										value={remarks}
										onChange={(e) => setRemarks(e.target.value)}
									/>
								</Form.Group>
							)}
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-dark"
							className="modal-btn bold-text text-sm me-2 dismiss-btn"
							onClick={() => setShowCodeModal(false)}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="modal-btn bold-text text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Loading" : "Save"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal
				show={showGuestCheckoutModal}
				onHide={() => setShowGuestCheckoutModal(false)}
			>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Checkout
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					{passes.filter((guest) => guest.status === "Checked In").length ===
						1 && (
						<>
							<span className="guest-checkout-warning bold-text">Warning:</span>
							<p className="text-sm">
								This will automatically check out the booking itself.
							</p>
						</>
					)}
					<p className="text-sm">
						Are you sure you want to proceed with this check out?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-dark"
						className="modal-btn bold-text text-sm me-2 dismiss-btn"
						onClick={() => setShowGuestCheckoutModal(false)}
					>
						Cancel
					</Button>
					<Button
						variant="warning"
						className="modal-btn bold-text text-sm"
						disabled={actionLoading}
						onClick={checkoutGuest}
					>
						{actionLoading ? "Loading" : "Checkout"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showRejectModal} onHide={() => setShowRejectModal(false)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Reject
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={(e) => changeStatus(e, "reject")}>
					<Modal.Body className="pt-4 pb-4">
						<p className="text-sm">
							Are you sure you want to reject this booking?
						</p>
						<Form.Group className="mb-3">
							<Form.Label>Remarks</Form.Label>
							<Form.Control
								autoFocus
								required
								as="textarea"
								rows={5}
								value={bookingRemarks}
								onChange={(e) => setBookingRemarks(e.target.value)}
							/>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-dark"
							className="modal-btn bold-text text-sm me-2 dismiss-btn"
							onClick={() => setShowRejectModal(false)}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="danger"
							className="modal-btn bold-text text-sm"
							disabled={actionLoading}
						>
							{rejectLoading ? "Loading" : "Reject"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
			<Modal show={showCancelModal} onHide={() => setShowCancelModal(false)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Cancel
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					<p className="text-sm">
						Are you sure you want to cancel this booking?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-dark"
						className="modal-btn bold-text text-sm me-2 dismiss-btn"
						onClick={() => setShowCancelModal(false)}
					>
						Close
					</Button>
					<Button
						variant="danger"
						className="modal-btn bold-text text-sm"
						disabled={actionLoading}
						onClick={(e) => changeStatus(e, "cancel")}
					>
						{cancelLoading ? "Loading" : "Cancel"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Confirm
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					<p className="text-sm">
						Are you sure you want to confirm this booking?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-dark"
						className="modal-btn bold-text text-sm me-2 dismiss-btn"
						onClick={() => setShowConfirmModal(false)}
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						className="modal-btn bold-text text-sm"
						disabled={actionLoading}
						onClick={(e) => changeStatus(e, "confirm")}
					>
						{confirmLoading ? "Loading" : "Confirm"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal show={showRevertModal} onHide={() => setShowRevertModal(false)}>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Revert
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					<p className="text-sm">
						Are you sure you want to revert this booking?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-dark"
						className="modal-btn bold-text text-sm me-2 dismiss-btn"
						onClick={() => setShowRevertModal(false)}
					>
						Cancel
					</Button>
					<Button
						variant="primary"
						className="modal-btn bold-text text-sm"
						disabled={actionLoading}
						onClick={revertBooking}
					>
						{revertLoading ? "Loading" : "Confirm"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showCheckoutModal}
				onHide={() => setShowCheckoutModal(false)}
			>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Confirm
					</Modal.Title>
				</Modal.Header>
				<Modal.Body className="pt-4 pb-4">
					<p className="text-sm">
						Are you sure you want to check out all guests from this booking?
					</p>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="outline-dark"
						className="modal-btn bold-text text-sm me-2 dismiss-btn"
						onClick={() => setShowCheckoutModal(false)}
					>
						Cancel
					</Button>
					<Button
						variant="warning"
						className="modal-btn bold-text text-sm"
						disabled={actionLoading}
						onClick={(e) => changeStatus(e, "checkout")}
					>
						{confirmLoading ? "Loading" : "Check Out"}
					</Button>
				</Modal.Footer>
			</Modal>
			<Modal
				show={showImage}
				size="xl"
				className="img-modal"
				centered
				onHide={() => setShowImage(null)}
			>
				<TransformWrapper>
					{({ zoomIn, zoomOut, resetTransform, ...rest }) => (
						<Modal.Body className="flex-column">
							<div className="img-tools flex-row-center">
								<Button
									className="flex-row-center btn-default blue-text"
									onClick={() => zoomIn()}
								>
									+
								</Button>
								<Button
									className="flex-row-center btn-default blue-text"
									onClick={() => zoomOut()}
								>
									-
								</Button>
							</div>
							<TransformComponent>
								<img src={showImage} className="img-open" />
							</TransformComponent>
						</Modal.Body>
					)}
				</TransformWrapper>
			</Modal>
			<Modal
				show={selfEdit}
				static
				onHide={() => handleSelfClose(selfEdit.key)}
			>
				<Modal.Header>
					<Modal.Title className="popup-modal-header medium-text">
						Add Remarks
					</Modal.Title>
				</Modal.Header>
				<Form onSubmit={(e) => editSelf(e, selfEdit.key, selfEdit.value)}>
					<Modal.Body className="pt-4 pb-4">
						<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
							<Form.Label>Remarks</Form.Label>
							<Form.Control
								required
								as="textarea"
								rows={5}
								value={selfRemarks}
								onChange={(e) => setSelfRemarks(e.target.value)}
							/>
						</Form.Group>
					</Modal.Body>
					<Modal.Footer>
						<Button
							variant="outline-dark"
							className="modal-btn bold-text text-sm me-2 dismiss-btn"
							onClick={() => handleSelfClose(selfEdit.key)}
						>
							Cancel
						</Button>
						<Button
							type="submit"
							variant="primary"
							className="modal-btn bold-text text-sm"
							disabled={actionLoading}
						>
							{actionLoading ? "Loading" : "Save"}
						</Button>
					</Modal.Footer>
				</Form>
			</Modal>
		</>
	);
};

export default Booking;
