import React from "react";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext, useRef } from "react";
import { SpaContext } from "../context/SpaContext";
import { ArrowBackOutlined, FileUploadOutlined } from "@mui/icons-material";
import { Wizard, Steps, Step } from "react-albus";
import {
	format,
	startOfToday,
	differenceInDays,
	eachDayOfInterval,
} from "date-fns";
import { useSnackbar } from "react-simple-snackbar";
import { DateRange } from "react-date-range";
import AWS from "aws-sdk";

import Section from "../components/Section";
import Container from "../components/Container";
import Row from "../components/Row";
import Column from "../components/Column";
import Table from "react-bootstrap/Table";
import GuestRow from "../components/GuestRow";
import Button from "react-bootstrap/esm/Button";
import Form from "react-bootstrap/Form";
import { v4 as uuidv4 } from "uuid";
import imageCompression from "browser-image-compression";
import Loader from "../components/Loader";
import ImageUpload from "../components/ImageUpload";
import { useStepContext } from "@mui/material";

const SpaBookingForm = () => {
	const ref = useRef();
	const navigate = useNavigate();
	const [spaUser] = useContext(SpaContext);
	const [guests, setGuests] = useState([]);
	const [guestIndices, setGuestIndices] = useState([1]);
	const [activeIndex, setActiveIndex] = useState(1);
	const [lockedIndex, setLockedIndex] = useState(null);
	const [tenant, fetchTenant] = useState([]);
	const [units, fetchUnits] = useState([]);
	const [parkings, fetchParkings] = useState([]);
	const [detailsErrors, setDetailsErrors] = useState([]);
	const [primaryErrors, setPrimaryErrors] = useState([]);
	const [additionalErrors, setAdditionalErrors] = useState([]);
	const [loading, setLoading] = useState(true);
	const [saving, setSaving] = useState(false);
	const [dateConflict, setDateConflict] = useState(false);
	const [unit, setUnit] = useState("");
	const [contactPerson, setContactPerson] = useState("");
	const [contactPersonMsisdn, setContactPersonMsisdn] = useState("");
	const [arrivalTime, setArrivalTime] = useState("");
	const [departureTime, setDepartureTime] = useState("");
	const [selfCheckin, setSelfCheckin] = useState(false);
	const [selfCheckout, setSelfCheckout] = useState(false);
	const [primaryGuestName, setPrimaryGuestName] = useState("");
	const [primaryGuestNationality, setPrimaryGuestNationality] = useState("");
	const [primaryGuestAge, setPrimaryGuestAge] = useState("");
	const [primaryGuestEmail, setPrimaryGuestEmail] = useState("");
	const [primaryGuestMsisdn, setPrimaryGuestMsisdn] = useState("");
	const [primaryGuestAddress, setPrimaryGuestAddress] = useState("");
	const [primaryGuestId, setPrimaryGuestId] = useState("");
	const [primaryGuestIdPreview, setPrimaryGuestIdPreview] = useState("");
	const [primaryGuestVax, setPrimaryGuestVax] = useState("");
	const [primaryGuestVaxPreview, setPrimaryGuestVaxPreview] = useState("");
	const [primaryGuestPool, setPrimaryGuestPool] = useState([]);
	const [availablePoolDays, setAvailablePoolDays] = useState([]);
	const [primaryWithVehicle, setPrimaryWithVehicle] = useState(false);
	const [primaryVehicleType, setPrimaryVehicleType] = useState("Car");
	const [primaryVehicleModel, setPrimaryVehicleModel] = useState("");
	const [primaryVehiclePlate, setPrimaryVehiclePlate] = useState("");
	const [primaryWithParking, setPrimaryWithParking] = useState(false);
	const [primaryParkingTower, setPrimaryParkingTower] = useState("");
	const [primaryParkingLevel, setPrimaryParkingLevel] = useState("");
	const [primaryParkingSlot, setPrimaryParkingSlot] = useState("");
	const [primaryParkingPayment, setPrimaryParkingPayment] = useState("Cash");
	const [adults, setAdults] = useState(0);
	const [children, setChildren] = useState(0);
	const [paymentMode, setPaymentMode] = useState("Cash");

	const [primaryGuestIdName, setPrimaryGuestIdName] = useState("");
	const [primaryGuestVaxName, setPrimaryGuestVaxName] = useState("");

	const [primaryGuestIdProgress, setPrimaryGuestIdProgress] = useState(0);
	const [primaryGuestVaxProgress, setPrimaryGuestVaxProgress] = useState(0);

	const [primaryGuestIdUploading, setPrimaryGuestIdUploading] = useState(false);
	const [primaryGuestVaxUploading, setPrimaryGuestVaxUploading] =
		useState(false);

	const [dates, setDates] = useState([
		{
			startDate: new Date(),
			endDate: new Date(),
			key: "selection",
		},
	]);

	const S3_BUCKET = "stlc";
	const REGION = "ap-southeast-1";

	AWS.config.update({
		accessKeyId: process.env.REACT_APP_STORAGE_KEY_ID,
		secretAccessKey: process.env.REACT_APP_STORAGE_ACCESS_KEY,
	});

	const myBucket = new AWS.S3({
		endpoint: "sgp1.digitaloceanspaces.com",
		params: { Bucket: S3_BUCKET },
	});

	const handleFileInput = (
		e,
		updatePath,
		updateName,
		updateProgress,
		updateUploading,
		setPreview
	) => {
		updateUploading(true);
		let filename = `${uuidv4()}.${e.target.files[0].name.split(".").pop()}`;
		updateName(filename);

		var options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 1920,
			useWebWorker: true,
		};

		imageCompression(e.target.files[0], options).then(function (
			compressedFile
		) {
			const params = {
				Body: compressedFile,
				Bucket: S3_BUCKET,
				Key: `bookings/${filename}`,
				ACL: "private",
			};

			myBucket
				.putObject(params)
				.on("httpUploadProgress", (evt) => {
					updateProgress(Math.round((evt.loaded / evt.total) * 100));
				})
				.send((err) => {
					if (err) console.log(err);
					else {
						setPreview(
							`${process.env.REACT_APP_SERVER_URI}/bookings/images/${filename}`
						);
						updatePath(
							`${process.env.REACT_APP_SERVER_URI}/bookings/images/${filename}`
						);
					}
					updateUploading(false);
				});
		});
	};

	const detailsFields = [
		{ name: "unit", value: unit },
		// { name: "arrivalDate", value: arrivalDate },
		{ name: "arrivalTime", value: arrivalTime },
		// { name: "departureDate", value: departureDate },
		{ name: "departureTime", value: departureTime },
		{ name: "contactPerson", value: contactPerson },
		{ name: "contactPersonMsisdn", value: contactPersonMsisdn },
	];

	const primaryFields = [
		{ name: "primaryGuestName", value: primaryGuestName },
		{ name: "primaryGuestNationality", value: primaryGuestNationality },
		{ name: "primaryGuestAge", value: primaryGuestAge },
		{ name: "primaryGuestEmail", value: primaryGuestEmail },
		{ name: "primaryGuestMsisdn", value: primaryGuestMsisdn },
		{ name: "primaryGuestAddress", value: primaryGuestAddress },
		{ name: "primaryGuestId", value: primaryGuestId },
	];

	const [dangerSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#d81e5b",
			color: "#fff",
		},
	});

	const [successSnackbar] = useSnackbar({
		style: {
			zIndex: 10,
			backgroundColor: "#44C4A1",
			color: "#fff",
		},
	});

	const getData = async () => {
		setLoading(true);
		const requestOptions = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				"X-Auth-Token": spaUser.auth_token,
			},
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/tenants/spas/s/${spaUser.spa_id}`,
			requestOptions
		);

		const data = await response.json();

		if (!response.ok) {
			console.log(data.msg);
		} else {
			fetchTenant(data.tenant);
			fetchUnits(data.units);
			fetchParkings(data.parkings);
		}
		setLoading(false);
	};

	const checkDates = async () => {
		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": spaUser.auth_token,
			},
			body: JSON.stringify({
				unit_id: unit,
				dates: dates,
				arrival_time: arrivalTime,
				departure_time: departureTime,
			}),
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/dates/validate`,
			requestOptions
		);

		const data = await response.json();

		if (!response.ok) {
			console.log("Something went wrong.");
		} else {
			setDateConflict(data.has_conflict);
		}
	};

	const navigateWizard = (e, func, step, direction) => {
		e.preventDefault();
		if (validate(step, direction)) {
			if (step === "additional") {
				setActiveIndex(null);
			}
			var updatedAdults = 0;
			var updatedChildren = 0;
			if (primaryGuestAge) {
				if (primaryGuestAge > 5) {
					updatedAdults += 1;
				} else {
					updatedChildren += 1;
				}
			}
			guests.map((guest) => {
				updatedChildren += guest.belowMinHeight ? 1 : 0;
				updatedAdults += guest.belowMinHeight ? 0 : 1;
			});
			setAdults(updatedAdults);
			setChildren(updatedChildren);
			func();
			setTimeout(() => {
				window.scrollTo(0, 0);
			}, 500);
		}
	};

	const updatePrimaryPoolDays = (event, list, func) => {
		var updatedList = [...list];
		if (event.target.checked) {
			updatedList = [...list, event.target.value];
		} else {
			updatedList.splice(list.indexOf(event.target.value), 1);
		}
		func(updatedList);
	};

	const updatePoolDays = (e, guest) => {
		var currentGuest = { ...guest };
		if (e.target.checked) {
			currentGuest.pool = [...currentGuest.pool, e.target.value];
		} else {
			currentGuest.pool = [
				...currentGuest.pool.filter((pool) => pool !== e.target.value),
			];
		}
		setGuests((prevGuests) => [
			...prevGuests.filter(
				(prevGuest) => prevGuest.index !== currentGuest.index
			),
			currentGuest,
		]);
	};

	const handleSubmit = async () => {
		setSaving(true);
		const form = new FormData();
		form.append("from_spa", true);
		form.append("unit", unit);
		form.append("arrival_time", arrivalTime);
		form.append("departure_time", departureTime);
		form.append("dates", JSON.stringify(dates));
		form.append("self_checkin", JSON.stringify(selfCheckin));
		form.append("self_checkout", JSON.stringify(selfCheckout));
		form.append("primary_guest_name", primaryGuestName);
		form.append("primary_guest_nationality", primaryGuestNationality);
		form.append("primary_guest_age", primaryGuestAge);
		form.append("primary_guest_email", primaryGuestEmail);
		form.append("primary_guest_msisdn", primaryGuestMsisdn);
		form.append("primary_guest_address", primaryGuestAddress);
		form.append("primary_with_vehicle", JSON.stringify(primaryWithVehicle));
		form.append("primary_vehicle_type", primaryVehicleType);
		form.append("primary_vehicle_model", primaryVehicleModel);
		form.append("primary_vehicle_plate", primaryVehiclePlate);
		form.append("primary_with_parking", JSON.stringify(primaryWithParking));
		form.append("primary_parking_tower", primaryParkingTower);
		form.append("primary_parking_level", primaryParkingLevel);
		form.append("primary_parking_slot", primaryParkingSlot);
		form.append("primary_parking_payment", primaryParkingPayment);
		form.append("guests", JSON.stringify(guests));
		form.append("adults", adults);
		form.append("children", children);
		form.append("primary_guest_id", primaryGuestIdName);
		form.append("primary_guest_vax", primaryGuestVaxName);
		form.append("contact_person", contactPerson);
		form.append("contact_person_msisdn", contactPersonMsisdn);
		form.append("payment_mode", paymentMode);
		form.append(
			"total_swimbands",
			primaryGuestPool.length +
				guests.reduce((prev, next) => prev + next.pool.length, 0)
		);
		form.append(
			"total_amount",
			(primaryGuestPool.length +
				guests.reduce((prev, next) => prev + next.pool.length, 0)) *
				250
		);

		primaryGuestPool.forEach((primaryPool) =>
			form.append("primary_guest_pool[]", primaryPool)
		);

		const requestOptions = {
			method: "POST",
			headers: {
				"X-Auth-Token": spaUser.auth_token,
			},
			body: form,
		};

		const response = await fetch(
			`${process.env.REACT_APP_SERVER_URI}/bookings/create`,
			requestOptions
		);

		const data = await response.json();

		if (!response.ok) {
			dangerSnackbar(data.msg || "Something went wrong.");
			setSaving(false);
		} else {
			successSnackbar(data.msg);
			navigate(`/bookings${window.location.search}`);
		}
	};

	const addGuest = () => {
		var activeUnit = units.filter((tenant_unit) => tenant_unit.id == unit)[0];
		var maxGuests = activeUnit.max_guests - 1;

		if (maxGuests > guestIndices.length) {
			var newIndices = [...guestIndices, guestIndices.length + 1];
			setGuestIndices(newIndices);
			setActiveIndex(newIndices.length);
		} else {
			if (maxGuests + 1 > guestIndices.length) {
				var newIndices = [...guestIndices, guestIndices.length + 1];
				setGuestIndices(newIndices);
				setActiveIndex(newIndices.length);
				setLockedIndex(newIndices.length);
			} else {
				dangerSnackbar("Maximum guests reached.");
			}
		}
	};

	const deleteGuest = (index) => {
		setGuests((prevGuests) => [
			...prevGuests.filter((prevGuest) => prevGuest.index !== index),
		]);
		setGuestIndices((prevIndices) => [
			...prevIndices.filter((prevIndex) => prevIndex !== index),
		]);
	};

	useEffect(() => {
		document.title = "Azure | New Booking";
		getData();
		setGuests([]);
	}, []);

	useEffect(() => {
		if (dates.length !== 0) {
			if (dates[0].startDate && dates[0].endDate) {
				setAvailablePoolDays(
					eachDayOfInterval({
						start: new Date(dates[0].startDate),
						end: new Date(dates[0].endDate),
					})
				);
				const emptyGuestPool = guests.map((guest) => {
					return { ...guest, pool: [] };
				});
				setGuests(emptyGuestPool);
				setPrimaryGuestPool([]);
			}
		}
	}, [dates]);

	useEffect(() => {
		if (
			unit &&
			dates[0].startDate &&
			dates[0].endDate &&
			arrivalTime &&
			departureTime
		) {
			checkDates();
		}
	}, [unit, dates, arrivalTime, departureTime]);

	useEffect(() => {
		setGuestIndices([1]);
		setGuests([]);
	}, [unit]);

	// VALIDATIONS

	const validate = (step, direction) => {
		if (direction === "next") {
			setDetailsErrors([]);
			setPrimaryErrors([]);
			setAdditionalErrors([]);
			switch (step) {
				case "details":
					var errors = [];
					detailsFields.map((field) => {
						if (!field.value) {
							errors.push(field.name);
						}
					});
					setDetailsErrors(errors);

					if (errors.length != 0) {
						dangerSnackbar("Please fill out all required fields.");
						return false;
					}
					if (
						dates[0].endDate.toDateString() ===
							dates[0].startDate.toDateString() &&
						arrivalTime >= departureTime
					) {
						setDetailsErrors(["arrivalTime", "departureTime"]);
						dangerSnackbar("Arrival should be earlier than departure.");
						return false;
					}
					// if (!selfCheckin) {
					// 	if (
					// 		contactPersonMsisdn.slice(0, 2) !== "09" ||
					// 		contactPersonMsisdn.length !== 11
					// 	) {
					// 		setDetailsErrors(["contactPersonMsisdn"]);
					// 		dangerSnackbar("Invalid mobile no.");
					// 		return false;
					// 	}
					// }
					if (dateConflict) {
						setDetailsErrors(["dates"]);
						dangerSnackbar("Dates unavailable.");
						return false;
					}
					if (differenceInDays(dates[0].endDate, dates[0].startDate) > 29) {
						setDetailsErrors(["dates"]);
						dangerSnackbar("Maximum stay is 29 days.");
						return false;
					}
					return true;
				case "primary":
					var errors = [];
					primaryFields.map((field) => {
						if (!field.value) {
							errors.push(field.name);
						}
					});
					if (primaryWithVehicle) {
						if (!primaryVehicleType) {
							errors.push("primaryVehicleType");
						}
						if (!primaryVehicleModel) {
							errors.push("primaryVehicleModel");
						}
						if (!primaryVehiclePlate) {
							errors.push("primaryVehiclePlate");
						}
						if (primaryWithParking) {
							// if (!primaryParkingTower) {
							// 	errors.push("primaryParkingTower");
							// }
							// if (!primaryParkingLevel) {
							// 	errors.push("primaryParkingLevel");
							// }
							if (!primaryParkingSlot) {
								errors.push("primaryParkingSlot");
							}
						}
					}
					setPrimaryErrors(errors);

					if (errors.length != 0) {
						dangerSnackbar("Please fill out all required fields.");
						return false;
					}
					// if (
					// 	primaryGuestMsisdn.slice(0, 2) !== "09" ||
					// 	primaryGuestMsisdn.length !== 11
					// ) {
					// 	setPrimaryErrors(["primaryGuestMsisdn"]);
					// 	dangerSnackbar("Invalid mobile no.");
					// 	return false;
					// }
					if (primaryGuestIdUploading || primaryGuestVaxUploading) {
						dangerSnackbar("Please wait for the upload to finish.");
						return false;
					}
					return true;
				case "additional":
					var errors = [];
					guests.map((guest) => {
						if (!guest.name) {
							errors.push("name" + guest.index);
						}
						if (!guest.age) {
							errors.push("age" + guest.index);
						}
						if (!guest.belowMinHeight && parseInt(guest.age) > 17) {
							if (!guest.id) {
								errors.push("id" + guest.index);
							}
						}
						if (guest.withVehicle) {
							if (!guest.vehicleType) {
								errors.push("vehicleType" + guest.index);
							}
							if (!guest.vehicleModel) {
								errors.push("vehicleModel" + guest.index);
							}
							if (!guest.vehiclePlate) {
								errors.push("vehiclePlate" + guest.index);
							}
							if (guest.withParking) {
								// if (!guest.parkingTower) {
								// 	errors.push("parkingTower" + guest.index);
								// }
								// if (!guest.parkingLevel) {
								// 	errors.push("parkingLevel" + guest.index);
								// }
								if (!guest.parkingSlot) {
									errors.push("parkingSlot" + guest.index);
								}
							}
						}
						setAdditionalErrors(errors);
					});
					if (errors.length != 0) {
						dangerSnackbar("Please fill out all required fields.");
						return false;
					}
					guests.map((guest) => {
						if (
							(guest.withParking === true &&
								primaryWithParking &&
								guest.parkingTower === primaryParkingTower &&
								guest.parkingLevel === primaryParkingLevel &&
								guest.parkingSlot === primaryParkingSlot) ||
							guests.filter(
								(guestFilter) =>
									guestFilter.withParking === true &&
									guestFilter.parkingTower === guest.parkingTower &&
									guestFilter.parkingLevel === guest.parkingLevel &&
									guestFilter.parkingSlot === guest.parkingSlot
							).length > 1
						) {
							errors.push("parkingSlot" + guest.index);
						}
					});
					if (errors.length != 0) {
						dangerSnackbar("Duplicate parking slots.");
						return false;
					}
					guests.map((guest) => {
						if (guest.id && guest.idProgress < 100) {
							errors.push("upload" + guest.index);
						}
					});
					if (errors.length != 0) {
						dangerSnackbar("Please wait for the upload to finish.");
						return false;
					}
					return true;
				default:
					return true;
			}
		} else {
			return true;
		}
	};

	const handleBack = () => {
		if (ref.current.state.step.id == "wizardDetails") {
			navigate(`/bookings${window.location.search}`);
		} else {
			ref.current.history.goBack();
		}
	};

	return (
		<>
			<div className="tenant-header dark-bg flex-row-center">
				<div className="back-btn flex-row-left" onClick={handleBack}>
					<ArrowBackOutlined sx={{ fontSize: 28 }} className="white-text" />
				</div>
				<span className="page-title white-text">New Booking</span>
			</div>
			{loading ? (
				<Loader className="booking-form-loader" />
			) : (
				<Section className="tenant-content booking-wizard full-page white-bg">
					<Wizard ref={ref}>
						<Steps>
							<Step
								id="wizardDetails"
								render={({ next }) => (
									<form
										onSubmit={(e) => navigateWizard(e, next, "details", "next")}
									>
										<Container>
											<Row className="align-center mt-3 mb-3">
												<Column>
													<h4>Booking Details</h4>
												</Column>
											</Row>
											<Row>
												<Column>
													<label className="blue-text form-label mb-2 bold-text">
														Unit
													</label>
													<Form.Select
														className={`form-control ${
															detailsErrors.includes("unit") && "border-danger"
														}`}
														defaultValue={unit}
														onChange={(e) => setUnit(e.target.value)}
													>
														<option value="" disabled></option>
														{units.map((unit) => (
															<option key={unit.id} value={unit.id}>
																{unit.tower} {unit.name}
															</option>
														))}
													</Form.Select>
												</Column>
											</Row>
											<Row className="mt-5">
												<Column>
													<label className="blue-text form-label mb-2 bold-text">
														Dates
													</label>
													<DateRange
														editableDateInputs={false}
														onChange={(item) => setDates([item.selection])}
														moveRangeOnFirstSelection={false}
														className="calendar-picker full"
														minDate={startOfToday()}
														ranges={dates}
														startDatePlaceholder={null}
														endDatePlaceholder={null}
													/>
													{/* <label className="blue-text form-label mb-2 bold-text">
													Arrival Date
												</label>
												{departureDate ? (
													<input
														type="date"
														className={`form-control ${
															detailsErrors.includes("arrivalDate") &&
															"border-danger"
														}`}
														min={format(new Date(startOfToday()), "uu-MM-dd")}
														max={format(new Date(departureDate), "uu-MM-dd")}
														value={arrivalDate}
														onChange={(e) => setArrivalDate(e.target.value)}
													/>
												) : (
													<input
														type="date"
														className={`form-control ${
															detailsErrors.includes("arrivalDate") &&
															"border-danger"
														}`}
														min={format(new Date(startOfToday()), "uu-MM-dd")}
														value={arrivalDate}
														onChange={(e) => setArrivalDate(e.target.value)}
													/>
												)} */}
												</Column>
												{/* <Column>
												<label className="blue-text form-label mb-2 bold-text">
													Arrival Time
												</label>
												<input
													type="time"
													className={`form-control ${
														detailsErrors.includes("arrivalTime") &&
														"border-danger"
													}`}
													value={arrivalTime}
													onChange={(e) => setArrivalTime(e.target.value)}
												/>
											</Column> */}
											</Row>
											<Row className="mt-5">
												{/* <Column>
												<label className="blue-text form-label mb-2 bold-text">
													Departure Date
												</label>
												<input
													type="date"
													className={`form-control ${
														detailsErrors.includes("departureDate") &&
														"border-danger"
													}`}
													min={format(
														new Date(
															arrivalDate
																? arrivalDate
																: addDays(startOfToday(), 1)
														),
														"uu-MM-dd"
													)}
													value={departureDate}
													onChange={(e) => setDepartureDate(e.target.value)}
												/>
											</Column> */}
												<Column>
													<label className="blue-text form-label mb-2 bold-text">
														Check In Time
													</label>
													<input
														type="time"
														className={`form-control ${
															detailsErrors.includes("arrivalTime") &&
															"border-danger"
														}`}
														value={arrivalTime}
														onChange={(e) => setArrivalTime(e.target.value)}
													/>
												</Column>
												<Column>
													<label className="blue-text form-label mb-2 bold-text">
														Check Out Time
													</label>
													<input
														type="time"
														className={`form-control ${
															detailsErrors.includes("departureTime") &&
															"border-danger"
														}`}
														value={departureTime}
														onChange={(e) => setDepartureTime(e.target.value)}
													/>
												</Column>
											</Row>
											<Row className="flex-row-left mt-5">
												<Column>
													<span className="blue-text form-label inline">
														Self Check In
													</span>
												</Column>
												<Column className="align-right">
													<input
														type="checkbox"
														className="cbx hidden"
														checked={selfCheckin}
														id="selfCheckin"
														onChange={() => setSelfCheckin(!selfCheckin)}
													/>
													<label htmlFor="selfCheckin" className="lbl"></label>
												</Column>
											</Row>
											<Row className="flex-row-left mt-4">
												<Column>
													<span className="blue-text form-label inline">
														Self Check Out
													</span>
												</Column>
												<Column className="align-right">
													<input
														type="checkbox"
														className="cbx hidden"
														checked={selfCheckout}
														id="selfCheckout"
														onChange={() => setSelfCheckout(!selfCheckout)}
													/>
													<label htmlFor="selfCheckout" className="lbl"></label>
												</Column>
											</Row>
											{/* {!selfCheckin && (
											<> */}
											<Row className="mt-5">
												<Column>
													<label className="blue-text form-label mb-2 bold-text">
														Contact Person
													</label>
													<input
														type="text"
														className={`form-control capitalize ${
															detailsErrors.includes("contactPerson") &&
															"border-danger"
														}`}
														value={contactPerson}
														onChange={(e) => setContactPerson(e.target.value)}
													/>
												</Column>
											</Row>
											<Row className="mt-5">
												<Column>
													<label className="blue-text form-label mb-2 bold-text">
														Contact Person's Mobile No.
													</label>
													<input
														type="text"
														className={`form-control ${
															detailsErrors.includes("contactPersonMsisdn") &&
															"border-danger"
														}`}
														value={contactPersonMsisdn}
														onChange={(e) =>
															setContactPersonMsisdn(e.target.value)
														}
													/>
												</Column>
											</Row>
											{/* </>
										)} */}
											<div className="wizard-btn-container">
												<Button
													type="submit"
													variant="primary"
													className="text-sm"
												>
													Next
												</Button>
											</div>
										</Container>
									</form>
								)}
							/>
							<Step
								id="wizardGuests"
								render={({ next, previous }) => (
									<form
										onSubmit={(e) => navigateWizard(e, next, "primary", "next")}
									>
										<Container>
											<Row className="align-center mt-3 mb-3">
												<Column>
													<h4>Primary Guest</h4>
												</Column>
											</Row>
											<Row>
												<Column>
													<label className="blue-text form-label mb-2 bold-text">
														Name
													</label>
													<input
														// autoFocus
														type="text"
														className={`form-control capitalize ${
															primaryErrors.includes("primaryGuestName") &&
															"border-danger"
														}`}
														value={primaryGuestName}
														autoComplete="off"
														onChange={(e) =>
															setPrimaryGuestName(e.target.value)
														}
													/>
												</Column>
											</Row>
											<Row className="mt-4">
												<Column>
													<label className="blue-text form-label mb-2 bold-text">
														Nationality
													</label>
													<input
														type="text"
														className={`form-control capitalize ${
															primaryErrors.includes(
																"primaryGuestNationality"
															) && "border-danger"
														}`}
														value={primaryGuestNationality}
														onChange={(e) =>
															setPrimaryGuestNationality(e.target.value)
														}
													/>
												</Column>
												<Column>
													<label className="blue-text form-label mb-2 bold-text">
														Age
													</label>
													<input
														type="number"
														className={`form-control ${
															primaryErrors.includes("primaryGuestAge") &&
															"border-danger"
														}`}
														value={primaryGuestAge}
														onChange={(e) => setPrimaryGuestAge(e.target.value)}
													/>
												</Column>
											</Row>
											<Row className="mt-4">
												<Column>
													<label className="blue-text form-label mb-2 bold-text">
														Email
													</label>
													<input
														type="email"
														className={`form-control ${
															primaryErrors.includes("primaryGuestEmail") &&
															"border-danger"
														}`}
														value={primaryGuestEmail}
														onChange={(e) =>
															setPrimaryGuestEmail(e.target.value)
														}
													/>
												</Column>
											</Row>
											<Row className="mt-4">
												<Column>
													<label className="blue-text form-label mb-2 bold-text">
														Contact No.
													</label>
													<input
														type="text"
														className={`form-control ${
															primaryErrors.includes("primaryGuestMsisdn") &&
															"border-danger"
														}`}
														value={primaryGuestMsisdn}
														onChange={(e) =>
															setPrimaryGuestMsisdn(e.target.value)
														}
													/>
												</Column>
											</Row>
											<Row className="mt-4">
												<Column>
													<label className="blue-text form-label mb-2 bold-text">
														Address
													</label>
													<textarea
														className={`form-control ${
															primaryErrors.includes("primaryGuestAddress") &&
															"border-danger"
														}`}
														value={primaryGuestAddress}
														onChange={(e) =>
															setPrimaryGuestAddress(e.target.value)
														}
													></textarea>
												</Column>
											</Row>
											<Row className="mt-4">
												<Column>
													<label className="blue-text form-label mb-2 bold-text">
														Valid ID
													</label>
													<ImageUpload
														additionalErrors={primaryErrors}
														previewImg={primaryGuestIdPreview}
														uploading={primaryGuestIdUploading}
														progress={primaryGuestIdProgress}
														inputLabel="primaryGuestId"
														index=""
													/>
													<input
														type="file"
														className="form-control hidden"
														id="primaryGuestId"
														accept="image/png, image/jpeg"
														onChange={(e) =>
															handleFileInput(
																e,
																setPrimaryGuestId,
																setPrimaryGuestIdName,
																setPrimaryGuestIdProgress,
																setPrimaryGuestIdUploading,
																setPrimaryGuestIdPreview
															)
														}
													/>
												</Column>
												<Column>
													<label className="blue-text form-label mb-2 bold-text">
														Vax Cert.
													</label>
													<ImageUpload
														additionalErrors={primaryErrors}
														previewImg={primaryGuestVaxPreview}
														uploading={primaryGuestVaxUploading}
														progress={primaryGuestVaxProgress}
														inputLabel="primaryGuestVax"
														index=""
													/>
													<input
														type="file"
														className="form-control hidden"
														id="primaryGuestVax"
														accept="image/png, image/jpeg"
														onChange={(e) =>
															handleFileInput(
																e,
																setPrimaryGuestVax,
																setPrimaryGuestVaxName,
																setPrimaryGuestVaxProgress,
																setPrimaryGuestVaxUploading,
																setPrimaryGuestVaxPreview
															)
														}
													/>
												</Column>
											</Row>
											<Row className="flex-row-left mt-5">
												<Column>
													<span className="blue-text form-label inline">
														With Vehicle
													</span>
												</Column>
												<Column className="align-right">
													<input
														type="checkbox"
														className="cbx hidden"
														checked={primaryWithVehicle}
														id="primaryWithVehicle"
														onChange={() =>
															setPrimaryWithVehicle(!primaryWithVehicle)
														}
													/>
													<label
														htmlFor="primaryWithVehicle"
														className="lbl"
													></label>
												</Column>
											</Row>
											{primaryWithVehicle && (
												<>
													<Row className="mt-4">
														<Column>
															<label className="blue-text form-label mb-2 bold-text">
																Type
															</label>
															<Form.Select
																className={`form-control ${
																	primaryErrors.includes(
																		"primaryVehicleType"
																	) && "border-danger"
																}`}
																value={primaryVehicleType}
																onChange={(e) =>
																	setPrimaryVehicleType(e.target.value)
																}
															>
																<option value="Car">Car</option>
																<option value="Motorcycle">Motorcycle</option>
															</Form.Select>
														</Column>
													</Row>
													<Row className="mt-4">
														<Column>
															<label className="blue-text form-label mb-2 bold-text">
																Make & Model
															</label>
															<input
																type="text"
																className={`form-control capitalize ${
																	primaryErrors.includes(
																		"primaryVehicleModel"
																	) && "border-danger"
																}`}
																value={primaryVehicleModel}
																onChange={(e) =>
																	setPrimaryVehicleModel(e.target.value)
																}
															/>
														</Column>
													</Row>
													<Row className="mt-4">
														<Column>
															<label className="blue-text form-label mb-2 bold-text">
																Plate No.
															</label>
															<input
																type="text"
																className={`form-control uppercase ${
																	primaryErrors.includes(
																		"primaryVehiclePlate"
																	) && "border-danger"
																}`}
																value={primaryVehiclePlate}
																onChange={(e) =>
																	setPrimaryVehiclePlate(e.target.value)
																}
															/>
														</Column>
													</Row>
													<Row className="flex-row-left mt-4">
														<Column>
															<span className="blue-text form-label inline">
																With Assigned Parking
															</span>
														</Column>
														<Column className="align-right">
															<input
																type="checkbox"
																className="cbx hidden"
																checked={primaryWithParking}
																id="primaryWithParking"
																onChange={() =>
																	setPrimaryWithParking(!primaryWithParking)
																}
															/>
															<label
																htmlFor="primaryWithParking"
																className="lbl"
															></label>
														</Column>
													</Row>
													{primaryWithParking ? (
														<>
															<Row className="mt-4">
																<Column>
																	<label className="blue-text form-label mb-2 bold-text">
																		Parking Slot
																	</label>
																	<Form.Select
																		className={`text-sm m-0 form-control ${
																			primaryErrors.includes(
																				"primaryParkingSlot"
																			) && "border-danger"
																		}`}
																		size="lg"
																		value={primaryParkingSlot}
																		onChange={(e) =>
																			setPrimaryParkingSlot(e.target.value)
																		}
																	>
																		<option value=""></option>
																		{parkings.length !== 0 &&
																			parkings.map((parking) => (
																				<option
																					key={parking.id}
																					value={parking.id}
																					disabled={
																						guests.filter(
																							(guest) =>
																								guest.withParking === true &&
																								parseInt(guest.parkingSlot) ===
																									parking.id
																						).length !== 0
																					}
																				>
																					{parking.tower} - {parking.level},{" "}
																					{parking.slot}
																				</option>
																			))}
																	</Form.Select>
																</Column>
															</Row>
														</>
													) : (
														<Row className="mt-4">
															<Column>
																<label className="blue-text form-label mb-2 bold-text">
																	Mode of Payment (Pay Parking)
																</label>
																<Form.Select
																	className="text-sm m-0 form-control"
																	size="lg"
																	value={primaryParkingPayment}
																	onChange={(e) =>
																		setPrimaryParkingPayment(e.target.value)
																	}
																>
																	<option value=""></option>
																	<option value="Cash">Cash</option>
																	<option value="Charge">Charge to Unit</option>
																</Form.Select>
															</Column>
														</Row>
													)}
												</>
											)}
											<div className="wizard-btn-container">
												<Row>
													<Column>
														<Button
															variant="light"
															className="text-sm default-text"
															onClick={(e) =>
																navigateWizard(
																	e,
																	previous,
																	"primary",
																	"previous"
																)
															}
														>
															Back
														</Button>
													</Column>
													<Column>
														<Button
															type="submit"
															variant="primary"
															className="text-sm"
														>
															Next
														</Button>
													</Column>
												</Row>
											</div>
										</Container>
									</form>
								)}
							/>
							<Step
								id="wizardAdditionalGuest"
								render={({ previous, next }) => (
									<form
										onSubmit={(e) =>
											navigateWizard(e, next, "additional", "next")
										}
									>
										<Container className="additional-guests-container">
											<Row className="align-center mt-3 mb-3">
												<Column>
													<h4>Additional Guests</h4>
												</Column>
											</Row>
											{guestIndices.map((index) => (
												<GuestRow
													key={index}
													parkings={parkings}
													primaryWithParking={primaryWithParking}
													primaryParkingSlot={primaryParkingSlot}
													guests={guests}
													setGuests={setGuests}
													index={index}
													locked={lockedIndex === index}
													additionalErrors={additionalErrors}
													activeIndex={activeIndex}
													setActiveIndex={setActiveIndex}
													deleteGuest={deleteGuest}
													bucket={myBucket}
													uuidv4={uuidv4}
													S3_BUCKET={S3_BUCKET}
												/>
											))}
											<Button
												variant="light"
												size="lg"
												className="add-guest-btn full default-text flex-row-center text-sm"
												onClick={addGuest}
											>
												Add Guest
											</Button>

											<div className="wizard-btn-container">
												<Row>
													<Column>
														<Button
															variant="light"
															className="text-sm default-text"
															onClick={(e) =>
																navigateWizard(
																	e,
																	previous,
																	"additional",
																	"previous"
																)
															}
														>
															Back
														</Button>
													</Column>
													<Column>
														<Button
															type="submit"
															variant="primary"
															className="text-sm"
														>
															Next
														</Button>
													</Column>
												</Row>
											</div>
										</Container>
									</form>
								)}
							/>
							<Step
								id="wizardPool"
								render={({ previous, next }) => (
									<form
										onSubmit={(e) => navigateWizard(e, next, "pool", "next")}
									>
										<Container>
											<Row className="align-center mt-3 mb-3">
												<Column>
													<h4>Pool Access</h4>
												</Column>
											</Row>
											{primaryGuestName ? (
												<Container>
													<Row className="header-row">
														<Column>
															<span className="white-text">
																{primaryGuestName}
															</span>
														</Column>
													</Row>
													<Row className="mb-5">
														<Column className="pool-input-group">
															<Row>
																<Column></Column>
																<Column className="align-center">AM</Column>
																<Column className="align-center">PM</Column>
															</Row>
															{availablePoolDays.map((poolDay, index) => (
																<Row
																	className="mt-3"
																	key={`${format(
																		new Date(poolDay),
																		"MMMdd"
																	)}primary`}
																>
																	<Column>
																		{format(new Date(poolDay), "MMM dd")}
																	</Column>
																	<Column className="align-center">
																		{index !== 0 &&
																		index !== availablePoolDays.length - 1 ? (
																			<>
																				<input
																					type="checkbox"
																					className="cbx hidden"
																					checked={primaryGuestPool.includes(
																						`${format(
																							new Date(poolDay),
																							"MMM dd, yyyy"
																						)}-AM`
																					)}
																					value={`${format(
																						new Date(poolDay),
																						"MMM dd, yyyy"
																					)}-AM`}
																					id={`${format(
																						new Date(poolDay),
																						"MMMdd"
																					)}AM-primary`}
																					onChange={(e) =>
																						updatePrimaryPoolDays(
																							e,
																							primaryGuestPool,
																							setPrimaryGuestPool
																						)
																					}
																				/>
																				<label
																					htmlFor={`${format(
																						new Date(poolDay),
																						"MMMdd"
																					)}AM-primary`}
																					className="lbl"
																				></label>
																			</>
																		) : index === 0 && arrivalTime < "12:00" ? (
																			<>
																				<input
																					type="checkbox"
																					className="cbx hidden"
																					checked={primaryGuestPool.includes(
																						`${format(
																							new Date(poolDay),
																							"MMM dd, yyyy"
																						)}-AM`
																					)}
																					value={`${format(
																						new Date(poolDay),
																						"MMM dd, yyyy"
																					)}-AM`}
																					id={`${format(
																						new Date(poolDay),
																						"MMMdd"
																					)}AM-primary`}
																					onChange={(e) =>
																						updatePrimaryPoolDays(
																							e,
																							primaryGuestPool,
																							setPrimaryGuestPool
																						)
																					}
																				/>
																				<label
																					htmlFor={`${format(
																						new Date(poolDay),
																						"MMMdd"
																					)}AM-primary`}
																					className="lbl"
																				></label>
																			</>
																		) : index ===
																				availablePoolDays.length - 1 &&
																		  departureTime > "08:00" ? (
																			<>
																				<input
																					type="checkbox"
																					className="cbx hidden"
																					checked={primaryGuestPool.includes(
																						`${format(
																							new Date(poolDay),
																							"MMM dd, yyyy"
																						)}-AM`
																					)}
																					value={`${format(
																						new Date(poolDay),
																						"MMM dd, yyyy"
																					)}-AM`}
																					id={`${format(
																						new Date(poolDay),
																						"MMMdd"
																					)}AM-primary`}
																					onChange={(e) =>
																						updatePrimaryPoolDays(
																							e,
																							primaryGuestPool,
																							setPrimaryGuestPool
																						)
																					}
																				/>
																				<label
																					htmlFor={`${format(
																						new Date(poolDay),
																						"MMMdd"
																					)}AM-primary`}
																					className="lbl"
																				></label>
																			</>
																		) : (
																			""
																		)}
																	</Column>
																	<Column className="align-center">
																		{index !== 0 &&
																		index !== availablePoolDays.length - 1 ? (
																			<>
																				<input
																					type="checkbox"
																					className="cbx hidden"
																					checked={primaryGuestPool.includes(
																						`${format(
																							new Date(poolDay),
																							"MMM dd, yyyy"
																						)}-PM`
																					)}
																					value={`${format(
																						new Date(poolDay),
																						"MMM dd, yyyy"
																					)}-PM`}
																					id={`${format(
																						new Date(poolDay),
																						"MMMdd"
																					)}PM-primary`}
																					onChange={(e) =>
																						updatePrimaryPoolDays(
																							e,
																							primaryGuestPool,
																							setPrimaryGuestPool
																						)
																					}
																				/>
																				<label
																					htmlFor={`${format(
																						new Date(poolDay),
																						"MMMdd"
																					)}PM-primary`}
																					className="lbl"
																				></label>
																			</>
																		) : index === 0 && arrivalTime < "19:00" ? (
																			<>
																				<input
																					type="checkbox"
																					className="cbx hidden"
																					checked={primaryGuestPool.includes(
																						`${format(
																							new Date(poolDay),
																							"MMM dd, yyyy"
																						)}-PM`
																					)}
																					value={`${format(
																						new Date(poolDay),
																						"MMM dd, yyyy"
																					)}-PM`}
																					id={`${format(
																						new Date(poolDay),
																						"MMMdd"
																					)}PM-primary`}
																					onChange={(e) =>
																						updatePrimaryPoolDays(
																							e,
																							primaryGuestPool,
																							setPrimaryGuestPool
																						)
																					}
																				/>
																				<label
																					htmlFor={`${format(
																						new Date(poolDay),
																						"MMMdd"
																					)}PM-primary`}
																					className="lbl"
																				></label>
																			</>
																		) : index ===
																				availablePoolDays.length - 1 &&
																		  departureTime > "12:00" ? (
																			<>
																				<input
																					type="checkbox"
																					className="cbx hidden"
																					checked={primaryGuestPool.includes(
																						`${format(
																							new Date(poolDay),
																							"MMM dd, yyyy"
																						)}-PM`
																					)}
																					value={`${format(
																						new Date(poolDay),
																						"MMM dd, yyyy"
																					)}-PM`}
																					id={`${format(
																						new Date(poolDay),
																						"MMMdd"
																					)}PM-primary`}
																					onChange={(e) =>
																						updatePrimaryPoolDays(
																							e,
																							primaryGuestPool,
																							setPrimaryGuestPool
																						)
																					}
																				/>
																				<label
																					htmlFor={`${format(
																						new Date(poolDay),
																						"MMMdd"
																					)}PM-primary`}
																					className="lbl"
																				></label>
																			</>
																		) : (
																			""
																		)}
																	</Column>
																</Row>
															))}
														</Column>
													</Row>
												</Container>
											) : (
												""
											)}
											{guests &&
												guests
													.filter((guest) => !guest.belowMinHeight)
													.sort((a, b) => a.index - b.index)
													.map((guest) => (
														<Container key={guest.index}>
															<Row className="header-row">
																<Column>
																	<span className="white-text">
																		{guest.name}
																	</span>
																</Column>
															</Row>
															<Row className="mb-5">
																<Column className="pool-input-group">
																	<Row>
																		<Column></Column>
																		<Column className="align-center">AM</Column>
																		<Column className="align-center">PM</Column>
																	</Row>
																	{availablePoolDays.map((poolDay, index) => (
																		<Row
																			className="mt-3"
																			key={`${format(
																				new Date(poolDay),
																				"MMMdd"
																			)}a`}
																		>
																			<Column>
																				{format(new Date(poolDay), "MMM dd")}
																			</Column>
																			<Column className="align-center">
																				{index !== 0 &&
																				index !==
																					availablePoolDays.length - 1 ? (
																					<>
																						<input
																							type="checkbox"
																							className="cbx hidden"
																							checked={guest.pool.includes(
																								`${format(
																									new Date(poolDay),
																									"MMM dd, yyyy"
																								)}-AM`
																							)}
																							value={`${format(
																								new Date(poolDay),
																								"MMM dd, yyyy"
																							)}-AM`}
																							id={`${format(
																								new Date(poolDay),
																								"MMMdd"
																							)}AM-${guest.index}`}
																							onChange={(e) =>
																								updatePoolDays(e, guest)
																							}
																						/>
																						<label
																							htmlFor={`${format(
																								new Date(poolDay),
																								"MMMdd"
																							)}AM-${guest.index}`}
																							className="lbl"
																						></label>
																					</>
																				) : index === 0 &&
																				  arrivalTime < "12:00" ? (
																					<>
																						<input
																							type="checkbox"
																							className="cbx hidden"
																							checked={guest.pool.includes(
																								`${format(
																									new Date(poolDay),
																									"MMM dd, yyyy"
																								)}-AM`
																							)}
																							value={`${format(
																								new Date(poolDay),
																								"MMM dd, yyyy"
																							)}-AM`}
																							id={`${format(
																								new Date(poolDay),
																								"MMMdd"
																							)}AM-${guest.index}`}
																							onChange={(e) =>
																								updatePoolDays(e, guest)
																							}
																						/>
																						<label
																							htmlFor={`${format(
																								new Date(poolDay),
																								"MMMdd"
																							)}AM-${guest.index}`}
																							className="lbl"
																						></label>
																					</>
																				) : index ===
																						availablePoolDays.length - 1 &&
																				  departureTime > "08:00" ? (
																					<>
																						<input
																							type="checkbox"
																							className="cbx hidden"
																							checked={guest.pool.includes(
																								`${format(
																									new Date(poolDay),
																									"MMM dd, yyyy"
																								)}-AM`
																							)}
																							value={`${format(
																								new Date(poolDay),
																								"MMM dd, yyyy"
																							)}-AM`}
																							id={`${format(
																								new Date(poolDay),
																								"MMMdd"
																							)}AM-${guest.index}`}
																							onChange={(e) =>
																								updatePoolDays(e, guest)
																							}
																						/>
																						<label
																							htmlFor={`${format(
																								new Date(poolDay),
																								"MMMdd"
																							)}AM-${guest.index}`}
																							className="lbl"
																						></label>
																					</>
																				) : (
																					""
																				)}
																			</Column>
																			<Column className="align-center">
																				{index !== 0 &&
																				index !==
																					availablePoolDays.length - 1 ? (
																					<>
																						<input
																							type="checkbox"
																							className="cbx hidden"
																							checked={guest.pool.includes(
																								`${format(
																									new Date(poolDay),
																									"MMM dd, yyyy"
																								)}-PM`
																							)}
																							value={`${format(
																								new Date(poolDay),
																								"MMM dd, yyyy"
																							)}-PM`}
																							id={`${format(
																								new Date(poolDay),
																								"MMMdd"
																							)}PM-${guest.index}`}
																							onChange={(e) =>
																								updatePoolDays(e, guest)
																							}
																						/>
																						<label
																							htmlFor={`${format(
																								new Date(poolDay),
																								"MMMdd"
																							)}PM-${guest.index}`}
																							className="lbl"
																						></label>
																					</>
																				) : index === 0 &&
																				  arrivalTime < "19:00" ? (
																					<>
																						<input
																							type="checkbox"
																							className="cbx hidden"
																							checked={guest.pool.includes(
																								`${format(
																									new Date(poolDay),
																									"MMM dd, yyyy"
																								)}-PM`
																							)}
																							value={`${format(
																								new Date(poolDay),
																								"MMM dd, yyyy"
																							)}-PM`}
																							id={`${format(
																								new Date(poolDay),
																								"MMMdd"
																							)}PM-${guest.index}`}
																							onChange={(e) =>
																								updatePoolDays(e, guest)
																							}
																						/>
																						<label
																							htmlFor={`${format(
																								new Date(poolDay),
																								"MMMdd"
																							)}PM-${guest.index}`}
																							className="lbl"
																						></label>
																					</>
																				) : index ===
																						availablePoolDays.length - 1 &&
																				  departureTime > "12:00" ? (
																					<>
																						<input
																							type="checkbox"
																							className="cbx hidden"
																							checked={guest.pool.includes(
																								`${format(
																									new Date(poolDay),
																									"MMM dd, yyyy"
																								)}-PM`
																							)}
																							value={`${format(
																								new Date(poolDay),
																								"MMM dd, yyyy"
																							)}-PM`}
																							id={`${format(
																								new Date(poolDay),
																								"MMMdd"
																							)}PM-${guest.index}`}
																							onChange={(e) =>
																								updatePoolDays(e, guest)
																							}
																						/>
																						<label
																							htmlFor={`${format(
																								new Date(poolDay),
																								"MMMdd"
																							)}PM-${guest.index}`}
																							className="lbl"
																						></label>
																					</>
																				) : (
																					""
																				)}
																			</Column>
																		</Row>
																	))}
																</Column>
															</Row>
														</Container>
													))}
											<div className="wizard-btn-container">
												<Row>
													<Column>
														<Button
															variant="light"
															className="text-sm default-text"
															onClick={(e) =>
																navigateWizard(e, previous, "pool", "previous")
															}
														>
															Back
														</Button>
													</Column>
													<Column>
														<Button
															type="submit"
															variant="primary"
															className="text-sm"
														>
															Next
														</Button>
													</Column>
												</Row>
											</div>
										</Container>
									</form>
								)}
							/>
							<Step
								id="wizardReview"
								render={({ previous }) => (
									<Container>
										<Row className="align-center mt-3 mb-3">
											<Column>
												<h4>Payment</h4>
											</Column>
										</Row>
										<Table>
											<tbody>
												<tr>
													<td>Total No. of Swimbands</td>
													<td className="align-right">
														{primaryGuestPool.length +
															guests.reduce(
																(prev, next) => prev + next.pool.length,
																0
															)}
													</td>
												</tr>
												<tr>
													<td>
														<strong>Total Amount</strong>
													</td>
													<td className="align-right">
														<strong>
															{(primaryGuestPool.length +
																guests.reduce(
																	(prev, next) => prev + next.pool.length,
																	0
																)) *
																250}
														</strong>
													</td>
												</tr>
											</tbody>
										</Table>
										{(primaryGuestPool.length +
											guests.reduce(
												(prev, next) => prev + next.pool.length,
												0
											)) *
											250 !==
										0 ? (
											<Row className="mt-5">
												<Column>
													<label className="blue-text mb-2">
														Mode of Payment
													</label>
													<Form.Select
														className="form-control"
														defaultValue="Cash"
														onChange={(e) => setPaymentMode(e.target.value)}
													>
														<option value="Cash">Cash</option>
														<option value="Charge">Charge to Unit Owner</option>
													</Form.Select>
												</Column>
											</Row>
										) : (
											""
										)}
										<div className="wizard-btn-container">
											<Row>
												<Column>
													<Button
														variant="light"
														className="text-sm default-text"
														onClick={(e) =>
															navigateWizard(e, previous, "payment", "previous")
														}
													>
														Back
													</Button>
												</Column>
												<Column>
													<Button
														variant="primary"
														className="text-sm"
														onClick={handleSubmit}
														disabled={saving}
													>
														{saving ? "Saving.." : "Submit"}
													</Button>
												</Column>
											</Row>
										</div>
									</Container>
								)}
							/>
						</Steps>
					</Wizard>
				</Section>
			)}
		</>
	);
};

export default SpaBookingForm;
